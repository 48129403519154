<template>
  <div class="pa-3 d-flex flex-column align-center _bg-default">
    <v-card pa-2 class="game">
      <v-toolbar color="indigo" class="mb-3" dense dark
        ><h3>Raynor</h3>
        <v-spacer></v-spacer>
        <v-btn dark @click="HintRaynor = true" text>Instruksi Game</v-btn>
      </v-toolbar>
      <v-col cols="12" md="12" v-if="fromRoute">
        <v-btn
          color="indigo"
          dark
          class="ml-3"
          v-if="!isPlayed"
          @click="sendText"
          >Mulai Game</v-btn
        >
      </v-col>
      <v-col cols="12" md="12" class="ml-3">
        <unity
          src="/Raynor/Build/Raynor.json"
          width="900"
          height="500"
          unityLoader="/Raynor/Build/UnityLoader.js"
          ref="myInstance"
        ></unity>
      </v-col>
    </v-card>
    <HintRaynor v-bind:HintRaynor="HintRaynor" @false="closeDialog" />
  </div>
</template>

<script>
import Unity from "vue-unity-webgl";
import HintRaynor from "../../components/_base/Game/HintRaynor.vue";

export default {
  name: "Game",
  components: {
    Unity,
    HintRaynor
  },
  data() {
    return {
      fromRoute: localStorage.getItem("from"),
      isPlayed: false,
      poin: 200,
      id: localStorage.getItem("id"),
      HintRaynor: true
    };
  },

  methods: {
    sendText() {
      this.$refs.myInstance.message(
        "Gamehandler",
        "StartGame",
        `${this.id},${this.fromRoute}`
      );
      this.isPlayed = true;
    },
    closeDialog() {
      this.HintRaynor = false;
    }
  }
};
</script>

<style scoped>
._bg-default {
  background: #f4f6f9;
}
.game {
  width: max-content;
}
</style>
